/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const UPDATE_RESOURCE = gql`
  mutation UpdateResource(
    $id: ID!
    $startDate: Date!
    $endDate: Date
    $role: String
    $name: String
    $email: String!
    $allowProjectManagement: Boolean
    $timesheetApprover: Boolean
    $note: String
    $inactive: Boolean
  ) {
    updateResource(
      id: $id
      input: {
        startDate: $startDate
        endDate: $endDate
        role: $role
        name: $name
        email: $email
        allowProjectManagement: $allowProjectManagement
        timesheetApprover: $timesheetApprover
        note: $note
        inactive: $inactive
      }
    )
  }
`;

export const ADD_RESOURCE = gql`
  mutation addResource(
    $projectId: ID!
    $startDate: Date!
    $endDate: Date
    $role: String
    $name: String
    $email: String!
    $allowProjectManagement: Boolean
    $timesheetApprover: Boolean
    $inactive: Boolean
  ) {
    addResource(
      projectId: $projectId
      input: {
        startDate: $startDate
        endDate: $endDate
        role: $role
        name: $name
        email: $email
        allowProjectManagement: $allowProjectManagement
        timesheetApprover: $timesheetApprover
        inactive: $inactive
      }
    )
  }
`;

export const REMOVE_RESOURCE = gql`
  mutation removeResource($id: ID!) {
    removeResource(id: $id)
  }
`;
