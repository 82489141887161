import { Table } from '@mantine/core';
// import { useQuery } from '@apollo/react-hooks';
// import gql from 'graphql-tag';

// Define an interface for the rollup structure
interface Rollup {
    [key: string]: {
        hours: number;
        count: number;
    };
}

// const GET_TIME_OFF_BALANCE = gql`
//     query GetTimeOffBalance($userId: String!, $timeOffType: String!) {
//         user(userId: $userId) {
//             timeOffBalance(timeOffType: $timeOffType)
//         }
//     }
// `;

const TimeOffTransactionsSummary = ({ transactions, userId }: { transactions: any[], userId?: string | null }) => {
    // const { data } = useQuery(GET_TIME_OFF_BALANCE, { variables: { userId, timeOffType: 'Paid Time Off' }, skip: !userId });
    // console.log(data);

    // Get unique timeOffTypes and transactionTypes
    const timeOffTypes = Array.from(new Set(transactions?.map(t => t.timeOffType)));

    // Rollup the transactions by timeOffType and transactionType
    const rollup = transactions?.reduce((acc: Rollup, row: any) => {
        const type = row.timeOffType;
        const transactionType = row.transactionType;
        const key = `${type}-${transactionType}`;
        acc[key] = acc[key] || { hours: 0, count: 0 };
        acc[key].hours += row.hours;
        acc[key].count += 1;
        return acc;
    }, {});

    const rows = timeOffTypes.map(timeOffType => (
        <tr key={timeOffType}>
            <td>{timeOffType}</td>
            <td>
                {rollup?.[`${timeOffType}-ROLLOVER`]?.hours || 0}
            </td>
            <td>
                {rollup?.[`${timeOffType}-EARNED`]?.hours || 0}
            </td>
            <td>
                {rollup?.[`${timeOffType}-TAKEN`]?.hours || 0}
            </td>
            <td>
                {rollup?.[`${timeOffType}-EARNED`]?.hours + (rollup?.[`${timeOffType}-ROLLOVER`]?.hours || 0) + (rollup?.[`${timeOffType}-TAKEN`]?.hours || 0) || 0}
            </td>
        </tr>
    ));

    return (
        <div className="">
            <div className="pb-2">Balances</div>
            <Table withBorder withColumnBorders>
                <thead>
                    <tr>
                        <th>Time Off Type</th>
                        <th>ROLLOVER</th>
                        <th>EARNED</th>
                        <th>TAKEN</th>
                        <th>REMAINING</th>
                    </tr>
                </thead>
                <tbody>{rows}</tbody>
            </Table>
        </div>
    );
};

export default TimeOffTransactionsSummary; 