import React, { useState } from 'react';
import { useGlobal } from 'reactn';
import { Query } from 'react-apollo';
import moment from 'moment';
import { Tag, Button, Input, Icon } from 'antd';
import Table from '../../common/components/Table';
import Card from '../../common/components/Card';
import Profile from '../../common/components/Profile';
import Modal from '../../common/components/Modal';
import UpdateResourceForm from '../../common/components/UpdateResourceForm';
import ProjectDetailsResourceActions from './ProjectDetailsResourceActions';
import { GET_RESOURCES_BY_PROJECT_ID } from '../queries';

const createColumns = ({
  resources,
  roles,
  refetchQueries,
  searchInput,
  onSearch,
  resetSearch,
}) => [
  {
    title: 'Resource',
    key: 'resource',
    width: '30%',
    render: ({ user, role, email, allowProjectManagement, timesheetApprover }) => {
      const { firstName, lastName, imageUrl } = user || {};
      return (
        <Profile
          allowProjectManagement={allowProjectManagement}
          timesheetApprover={timesheetApprover}
          imageUrl={imageUrl}
          title={role || 'Role not defined'}
          firstName={firstName}
          lastName={lastName}
          email={email}
        />
      );
    },
    sorter: (a, b) => {
      const nameA = `${a.user?.firstName} ${a.user?.lastName}`;
      const nameB = `${b.user?.firstName} ${b.user?.lastName}`;
      return nameA.localeCompare(nameB);
    },
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            searchInput = node;
          }}
          placeholder={`Search Name`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => onSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => onSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => resetSearch(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, { user, role, email }) => {
      const searchString = user?.firstName + user?.lastName + email + (role || user?.title);
      return searchString.toString().toLowerCase().includes(value.toLowerCase());
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: data => data && moment(data).format('MM/DD/YYYY'),
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: data => data && moment(data).format('MM/DD/YYYY'),
  },
  // {
  //   title: 'Allocation',
  //   dataIndex: 'allocation',
  //   key: 'allocation',
  //   render: data => data ? numeral(data / 100).format('0.[00]%') : null,
  // },
  // {
  //   title: 'Budgeted Hours',
  //   dataIndex: 'budgetedHours',
  //   key: 'budgetedHours',
  //   render: data => data ? numeral(data).format('0,00.[00]') : null,
  // },
  {
    title: 'Note',
    width: 300,
    dataIndex: 'note',
    key: 'note',
  },
  {
    title: 'Inactive',
    width: 300,
    dataIndex: 'inactive',
    key: 'inactive',
    render: data =>  data && <Tag color="red">Yes</Tag>
  },
  {
    title: 'Actions',
    key: 'actions',
    render: data => (
      <ProjectDetailsResourceActions
        roles={roles}
        resource={data}
        refetchQueries={refetchQueries}
      />
    ),
  },
];

const ProjectDetailsResourceList = ({ projectId, roles, isPaginated = true }) => {
  const [lockEditing] = useGlobal('lockEditing');
  const [searchInput, setSearchInput] = useState(null);
  const [showModal, setShowModal] = useState(false);

  return (
    <Card
      actionComponent={
        <div>
          <Button disabled={lockEditing} onClick={() => setShowModal(true)}>
            Add Resource
          </Button>
        </div>
      }
    >
      <Query query={GET_RESOURCES_BY_PROJECT_ID} variables={{ projectId }}>
        {({ loading, error, data }) => {
          if (error) throw new Error(error);
          const resources = data?.projectManagement?.project?.resources || [];
          const columns = createColumns({
            searchInput,
            resetSearch: clearFilters => {
              clearFilters();
              setSearchInput(null);
            },
            onSearch: (selectedKeys, confirm) => {
              confirm();
              setSearchInput(selectedKeys[0]);
            },
            resources,
            refetchQueries: [
              {
                query: GET_RESOURCES_BY_PROJECT_ID,
                variables: {
                  projectId,
                },
              },
            ],
            roles,
          });

          return (
            <div>
              <Modal
                title="Add Resource"
                visible={showModal}
                destroyOnClose
                onOk={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                footer={null}
              >
                <UpdateResourceForm
                  projectId={projectId}
                  roles={roles}
                  refetchQueries={[
                    {
                      query: GET_RESOURCES_BY_PROJECT_ID,
                      variables: {
                        projectId,
                      },
                    },
                  ]}
                  onComplete={() => setShowModal(false)}
                />
              </Modal>
              <Table
                columns={columns}
                loading={loading}
                pagination={isPaginated}
                dataSource={
                  // make sure resources exist and arrived to browser
                  resources && resources.map(r => ({ ...r, key: r._id }))
                }
              />
            </div>
          );
        }}
      </Query>
    </Card>
  );
};

export default ProjectDetailsResourceList;
