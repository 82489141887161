import { useQuery } from '@apollo/react-hooks';
import { Table, Select } from '@mantine/core';
import gql from 'graphql-tag';
import AddTimeOffTransactionForm from './AddTimeOffTransactionForm';
import TimeOffTransactionsSummary from './TimeOffBalancesSummary';
import AllTimeOffBalances from './AllTimeOffBalances';
import { useState } from 'react';
import moment from 'moment';

export const GET_TIME_OFF_BALANCES = gql`
  query GetTimeOffTransactions($userId: String!, $startDate: String, $endDate: String) {
    timeOffTransactions(userId: $userId, startDate: $startDate, endDate: $endDate) {
        _id
        userId
        timeOffType
        date
        transactionType
        hours
        user {
            _id
            firstName
            lastName
        }
    }
  }
`;

export const GET_TIME_OFF_TRANSACTIONS = gql`
  query GetTimeOffTransactions($userId: String!, $startDate: String, $endDate: String) {
me {
    timeOffTransactions(startDate: $startDate, endDate: $endDate) {
        transactionType
        timeOffType
        balance
        asOfDate
        hours
        user {
            _id
            firstName
            lastName
        }
    }
  }
}
`;

const GET_USERS = gql`
  query getUsers {
    admin {
      users {
        _id
        firstName
        lastName
        emails {
            address
        }
      }
    }
  }
`;

const TimeOffTransactions = ({ userId, allowAdd }: { userId: string, allowAdd: boolean }) => {
    const [selectedUserId, setSelectedUserId] = useState<string | null>(userId);
    const [selectedYear, setSelectedYear] = useState<string>(moment().year().toString());

    const { data: usersData, loading: usersLoading, error: usersError } = useQuery(GET_USERS, {
        skip: !allowAdd || !!userId,
    });

    const timeOffQueryVariables = { userId: selectedUserId, startDate: moment(selectedYear).startOf('year').format('YYYYMMDD'), endDate: moment(selectedYear).endOf('year').format('YYYYMMDD') };
    const { data: timeOffTransactions, loading: timeOffTransactionsLoading, error: timeOffTransactionsError } = useQuery(GET_TIME_OFF_BALANCES, {
        variables: timeOffQueryVariables,
        skip: !selectedUserId,
        fetchPolicy: 'network-only',
    });

    if (usersLoading || timeOffTransactionsLoading) return null;
    if (usersError || timeOffTransactionsError) return null;

    const users = usersData?.admin?.users;
    const rows = timeOffTransactions?.timeOffTransactions?.map((row: any) => (
        <tr key={row._id}>
            <td>{row.user.firstName} {row.user.lastName}</td>
            <td>{row.timeOffType}</td>
            <td>{moment(row.date).format('MM/DD/YYYY')}</td>
            <td>{row.transactionType}</td>
            <td>{row.hours}</td>
        </tr>
    ));

    return (
        <div className="flex flex-col gap-2">
            <div className="bg-white p-2 rounded-md flex flex-col gap-2">
                <div className="flex flex-row gap-2 justify-between">
                    {!userId && (
                        <Select
                            className="w-[500px]"
                            clearable
                            searchable
                            placeholder="Select a user"
                            data={users?.map((user: any) => ({ value: user._id, label: `${user.firstName} ${user.lastName} (${user.emails[0].address})` }))}
                            onChange={setSelectedUserId}
                            value={selectedUserId}
                        />)}
                    <div className="flex flex-row gap-2">
                        <Select
                            searchable
                            placeholder="Select a year"
                            data={Array.from({ length: 10 }, (_, i) => ({ value: moment().subtract(i, 'year').year().toString(), label: moment().subtract(i, 'year').year().toString() }))}
                            onChange={(value) => setSelectedYear(value || moment().year().toString())}
                            value={selectedYear}
                        />
                    </div>
                </div>

                {selectedUserId ? (
                    <div>
                        <TimeOffTransactionsSummary transactions={timeOffTransactions?.timeOffTransactions} userId={selectedUserId} />
                        <div className="mt-2">
                            {allowAdd && <AddTimeOffTransactionForm users={users} selectedUserId={selectedUserId} refetchQueries={[{ query: GET_TIME_OFF_BALANCES, variables: timeOffQueryVariables }]} />}
                            <div className="mb-2">Transactions</div>
                            <Table withBorder withColumnBorders>
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Time Off Type</th>
                                        <th>Date</th>
                                        <th>Transaction Type</th>
                                        <th>Hours</th>
                                    </tr>
                                </thead>
                                <tbody>{rows}</tbody>
                            </Table>
                        </div>
                    </div>) : (
                    <AllTimeOffBalances startDate={moment(selectedYear).startOf('year').format('YYYYMMDD')} endDate={moment(selectedYear).endOf('year').format('YYYYMMDD')} />
                )}
            </div>
        </div>
    );
};

export default TimeOffTransactions;

