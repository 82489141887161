import { useMutation } from '@apollo/react-hooks';
import { TextInput, Button, Select, Modal } from '@mantine/core';
import gql from 'graphql-tag';
import { useState } from 'react';
import moment from 'moment';
import useTaxonomy from '../../../../hooks/useTaxonomy';

const ADD_TIME_OFF_BALANCE = gql`
  mutation AddTimeOffTransaction($userId: ID!, $timeOffType: String!, $date: String!, $transactionType: String!, $hours: Float!) {
    addTimeOffTransactions(userId: $userId, input: { timeOffType: $timeOffType, date: $date, transactionType: $transactionType, hours: $hours }) {
      userId
    }
  }
`;

const AddTimeOffTransactionForm = ({ users, selectedUserId, refetchQueries }: { users: any, selectedUserId: any, refetchQueries: any }) => {
    const [opened, setOpened] = useState(false);
    const [addTimeOffTransaction, { loading: submitting }] = useMutation(ADD_TIME_OFF_BALANCE, {
        refetchQueries: refetchQueries,
        awaitRefetchQueries: true,
        onCompleted: () => {
            setOpened(false);
        }
    });

    const { data: timeOffTypesQuery } = useTaxonomy('Time Off'); // Use the custom hook for sectors
    const timeOffTypes = timeOffTypesQuery?.taxonomy?.terms || [];

    const [formData, setFormData] = useState({
        userId: selectedUserId,
        timeOffType: '',
        date: '',
        transactionType: '',
        hours: 0,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            await addTimeOffTransaction({ variables: { ...formData, hours: Number(formData.hours), date: moment(formData.date).format('YYYYMMDD') } });
            // Optionally, you can reset the form or handle success here
            setFormData({
                userId: selectedUserId,
                timeOffType: '',
                date: '',
                transactionType: '',
                hours: 0,
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="flex justify-end">
            <Button onClick={() => setOpened(true)}>Add</Button>
            <Modal opened={opened} onClose={() => setOpened(false)} title="Add Time Off Transaction">
                <form onSubmit={handleSubmit} className="flex flex-col gap-2">
                    <Select
                        name="userId"
                        label="User ID"
                        data={users?.map((user: any) => ({ value: user._id, label: `${user.firstName} ${user.lastName}` }))}
                        onChange={(value) => setFormData({ ...formData, userId: value || '' })}
                        required
                        searchable
                        value={formData.userId}
                    />
                    <Select
                        name="timeOffType"
                        label="Time Off Type"
                        data={timeOffTypes?.map((timeOffType: any) => ({ value: timeOffType.name, label: timeOffType.name }))}
                        onChange={(value) => setFormData({ ...formData, timeOffType: value || '' })}
                        required
                    />
                    <TextInput
                        name="date"
                        label="Date"
                        type="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                    />
                    <Select
                        name="transactionType"
                        label="Transaction Type"
                        data={['TAKEN', 'RESET', 'EARNED', 'ROLLOVER'].map((transactionType: any) => ({ value: transactionType, label: transactionType }))}
                        onChange={(value) => setFormData({ ...formData, transactionType: value || '' })}
                        required
                    />
                    <TextInput
                        name="hours"
                        label="Hours"
                        type="number"
                        value={formData.hours}
                        onChange={handleChange}
                        required
                    />
                    <Button type="submit" loading={submitting}>Submit</Button>

                </form>
            </Modal>
        </div>
    );
};

export default AddTimeOffTransactionForm; 