/* eslint-disable */
import { useEffect } from 'react';
import { Switch } from '@mantine/core';
import { useGlobal } from 'reactn';
import gql from 'graphql-tag';
import PasswordChange from './PasswordChange';
import Page from '../../common/components/Page';
import Card from '../../common/components/Card';
import { useQuery, useMutation } from 'react-apollo';
import TimeOffTransactions from '../../human-resources/components/time-off-balances/TimeOffTransactions';

const GET_MY_SETTINGS = gql`
  query GET_MY_SETTINGS {
    me {
      _id
      settings {
        useNewTaskSelector
      }
    }
  }
`;

const UPDATE_USER_SETTINGS = gql`
  mutation UpdateUserSettings($id: ID!, $input: UpdateUserSettingsInput!) {
    updateUserSettings(_id: $id, input: $input)
  }
`;

const ProfilePage = () => {
  const [updateUserSettings] = useMutation(UPDATE_USER_SETTINGS);
  const { data, refetch } = useQuery(GET_MY_SETTINGS);
  const [user, setUser] = useGlobal('user');
  const [config] = useGlobal('config');
  const showTimeOffBalances = config['show-timeoff-balances'] === '1';
  const supervisor = user.supervisor;

  // Initialize settings if they don't exist or if useNewTaskSelector is null
  useEffect(() => {
    if (
      data?.me?._id &&
      (data.me.settings?.useNewTaskSelector === null ||
        data.me.settings?.useNewTaskSelector === undefined)
    ) {
      updateUserSettings({
        variables: {
          id: data.me._id,
          input: {
            useNewTaskSelector: false,
          },
        },
      }).then(() => refetch());
    }
  }, [data?.me]);

  useEffect(() => {
    if (data?.me?._id) {
      setUser({
        ...user,
        settings: {
          ...user.settings,
          useNewTaskSelector: data.me.settings?.useNewTaskSelector,
        },
      });
    }
  }, [data?.me]);

  const storedUseNewTaskSelector = data?.me?.settings?.useNewTaskSelector ?? false;

  const handleTaskSelectorChange = async value => {
    const newValue = !storedUseNewTaskSelector;
    try {
      await updateUserSettings({
        variables: {
          id: data?.me?._id,
          input: {
            useNewTaskSelector: newValue,
          },
        },
      });
      refetch();
    } catch (error) {
      console.error('Failed to update settings:', error);
    }
  };

  return (
    <Page>
      <PasswordChange />
      {supervisor && (
        <Card title="Supervisor">
          <div className="flex flex-col justify-start gap-2 px-4 pb-4">
            <div>
              Name: {supervisor?.firstName} {supervisor?.lastName}
            </div>
            <div>Email: {supervisor?.emails[0]?.address}</div>
          </div>
        </Card>
      )}
      <Card title="Preview Features">
        <div className="flex flex-col justify-start p-4">
          <Switch
            label="Use new task selector"
            checked={storedUseNewTaskSelector}
            onChange={handleTaskSelectorChange}
          />
        </div>
      </Card>
      {showTimeOffBalances && (
        <Card title="Time Off Balances">
          <TimeOffTransactions userId={user._id} />
        </Card>
      )}
    </Page>
  );
};

export default ProfilePage;
