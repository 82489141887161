import React, { getGlobal } from 'reactn';
import { get } from 'lodash';
import moment from 'moment';
import styled from 'styled-components';
import Card from '../../common/components/Card';
import WeekPicker from '../../common/components/WeekPicker';
import TimesheetContainer from './TimesheetContainer';
import TimesheetUploadsContainer from './TimesheetUploadsContainer';
import { Query } from 'react-apollo';
import { GET_USER_TIMESHEET } from '../queries';

const Message = styled.div`
  color: red;
  font-size: 1.2em;
  margin-bottom: 1em;
`;

class TimesheetCard extends React.Component {
  changeUrl = endDate => this.props.history.push(`/user/timesheets/${endDate}`);

  componentDidMount() {
    const paramsEndDate = get(this.props, 'match.params.endDate');
    const { config } = getGlobal();
    const weekEndDay = config['week-end-day'];
    const dayMapping = { sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6 };

    // Get the current date from paramsEndDate
    const currentDate = moment(paramsEndDate);
    const currentDayOfWeek = currentDate.day();
    const targetDayOfWeek = dayMapping[weekEndDay];

    // Check if the current day matches the weekEndDay
    if (currentDayOfWeek === targetDayOfWeek) {
      return; // Do nothing if it matches
    }

    // Calculate the days to add to get to the next weekEndDay
    let daysToAdd = (targetDayOfWeek - currentDayOfWeek + 7) % 7;
    if (daysToAdd === 0) {
        daysToAdd = 7; // Move to the next week if it's the same day
    }

    // Calculate the next end date
    const nextEndDate = currentDate.add(daysToAdd, 'days');
    this.props.changeTimesheetDate(nextEndDate.format('YYYYMMDD'));
    this.props.history.replace(`/user/timesheets/${nextEndDate.format('YYYYMMDD')}`);
  }

  render() {
    const showAttachments = this.global?.config['timesheet-attachments'] === '1';
    const global = getGlobal();
    const endDate = get(this.props, 'match.params.endDate');

    return (
      <Query query={GET_USER_TIMESHEET} variables={{ endDate, user: null }}>
        {({ loading, error, data }) => {
          if (error) {
            window.location.reload();
          }
          return (
            <>
              {global?.config['company-short-name'] === 'Spruce' &&
                moment(get(this.props, 'match.params.endDate')).isBefore(moment('2023-09-18')) && (
                  <Message>
                    <strong>ATTENTION:</strong> This timesheet system will only accept time entries
                    starting on 9/18/2023. If you need to submit time for a date prior, please use{' '}
                    <a href={`https://portal.sprucetech.com/user/timesheets`}>
                      https://portal.sprucetech.com
                    </a>
                    .
                  </Message>
                )}
              <Card
                border
                title="Weekly Timesheet"
                padded={false}
                floating
                loading={data?._id || loading}
                withShadow
                actionComponent={
                  <WeekPicker
                    endDate={endDate}
                    onDateChange={endDate => {
                      const endDateString = moment(endDate).format('YYYYMMDD');
                      this.changeUrl(endDateString);
                      this.props.changeTimesheetDate(endDateString);
                    }}
                  />
                }
                style={{ minWidth: '1000px' }}
              >
                <TimesheetContainer timesheet={data?.timesheet} endDate={endDate} />
              </Card>
              {showAttachments && !loading && data?.timesheet && (
                <Card title="Attachments" border padded={false} floating withShadow>
                  <TimesheetUploadsContainer
                    readonly={['Submitted-Pending', 'Approved'].includes(data?.timesheet.status)}
                    timesheet={data?.timesheet}
                    endDate={endDate}
                  />
                </Card>
              )}
            </>
          );
        }}
      </Query>
    );
  }
}

export default TimesheetCard;
