// refresh the page if the user leaves for more than e.g. 10 minutes, to prevent network inconsistency
export function activityTimer(thresholdInMilliseconds: number) {
    let timestamp = new Date();
    document.addEventListener('visibilitychange', function () {
      if (document.hidden) {
        timestamp = new Date();
      } else {
        const now = new Date();
        let diff = now.getTime() - timestamp.getTime();
        //   milliseconds since page was last visible
        if (diff > thresholdInMilliseconds) {
          this.location.reload();
        }
      }
    });
  }
  