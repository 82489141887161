import { Table } from '@mantine/core';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import useTaxonomy from '../../../../hooks/useTaxonomy';
import React from 'react';

const GET_USERS = gql`
  query getUsers($startDate: String, $endDate: String) {
    admin {
      users {
        _id
        firstName
        lastName
        emails {
            address
        }
        timeOffBalances(startDate: $startDate, endDate: $endDate) {
            timeOffType
            earned
            taken
            rollover
            remaining
        }
      }
    }
  }
`;

const TimeOffTransactionsSummary = ({ startDate, endDate }: { startDate: string, endDate: string }) => {
    const { data: timeOffTypesQuery } = useTaxonomy('Time Off'); // Use the custom hook for sectors
    const timeOffTypes = timeOffTypesQuery?.taxonomy?.terms || [];

    const { data: usersQuery } = useQuery(GET_USERS, {
        variables: {
            startDate,
            endDate
        }
    });
    const users = usersQuery?.admin?.users;

    return (
        <div className="time-off-transactions-summary">
            <Table withBorder withColumnBorders striped highlightOnHover>
                <thead>
                    <tr>
                        <th rowSpan={2}>Name</th>
                        <th rowSpan={2}>Email</th>
                        {timeOffTypes.map((type: any) => (
                            <th colSpan={4} key={type._id}>
                                {type.name}
                            </th>
                        ))}
                    </tr>
                    <tr>
                        {timeOffTypes.map((type: any) => (
                            <React.Fragment key={type._id}>
                                <th>Rollover</th>
                                <th>Earned</th>
                                <th>Taken</th>
                                <th>Remaining</th>
                            </React.Fragment>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {users?.map((user: any) => {
                        const balances = timeOffTypes.map((type: any) => {
                            const balance = user.timeOffBalances.find((b: any) => b.timeOffType === type.name) || { earned: 0, taken: 0, remaining: 0, rollover: 0 };
                            return (
                                <React.Fragment key={type._id}>
                                    <td>{balance.rollover}</td>
                                    <td>{balance.earned}</td>
                                    <td>{balance.taken}</td>
                                    <td>{balance.remaining}</td>
                                </React.Fragment>
                            );
                        });

                        return (
                            <tr key={user._id}>
                                <td>{`${user.firstName} ${user.lastName}`}</td>
                                <td>{user.emails[0]?.address}</td>
                                {balances}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default TimeOffTransactionsSummary; 