import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AllUsersCard from './AllUsersCard';
import DelinquencyReportCard from './DelinquencyReportCard';
import HrReports from './HrReports';
import UserHourlyCostList from './UserHourlyCostList';
import UserTaskTotalsCardContainer from './UserTaskTotalsCardContainer';
import Page from '../../common/components/Page';
import TimeOffTransactions from './time-off-balances/TimeOffTransactions';

const HumanResourcesPage = () => {
  return (
    <Page full>
      <Switch>
        <Route exact path="/human-resources/employee-cost" render={() => <UserHourlyCostList />} />
        <Route exact path="/human-resources/reports" render={() => <HrReports />} />
        <Route exact path="/human-resources/reports/all-users" render={() => <AllUsersCard />} />
        <Route exact path="/human-resources/time-off-balances" render={() => <TimeOffTransactions allowAdd />} />
        <Route
          exact
          path="/human-resources/reports/delinquency"
          render={() => <DelinquencyReportCard />}
        />
        <Route
          path="/human-resources/reports/user-task-totals"
          render={() => <UserTaskTotalsCardContainer />}
        />
      </Switch>
    </Page>
  );
};

export default HumanResourcesPage;
