import { useState, useEffect } from 'react';
import styled from 'styled-components';
import Fuse from 'fuse.js';
import Input from '../Input';
import Card from '../Card';
import Table from '../Table';
import { Radio, Tooltip, Icon } from 'antd';

const { Search } = Input;
const RadioGroup = Radio.Group;

const RowFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const RowItem = styled.div`
  border-bottom: 1px solid #dbdbdb;
  background: white;
  &:last-child {
    border-bottom: none;
  }
  ${props => props.minHeight && `min-height: ${props.minHeight}`};
`;

const ActionWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  > * {
    // margin: 0 0.5rem;
    &:last-child {
      margin-right: 0;
    }
    &:first-child {
      margin-left: 0;
    }
  }
`;

const searchData = ({ data, searchIndex, searchKeys, searchString }) => {
  const options = {
    shouldSort: true,
    threshold: 0.3,
    location: 0,
    distance: 100,
    maxPatternLength: 10,
    minMatchCharLength: 1,
    keys: searchKeys,
    tokenize: true,
  };
  const fuse = new Fuse(data, options, searchIndex);
  return searchString ? fuse.search(searchString).map(r => r.item) : data;
};

const DataDisplay = ({
  data,
  onGridRender,
  onRowRender,
  itemMinWidth,
  itemMinHeight,
  searchKeys,
  defaultViewType = 'grid',
  onClick,
  loading,
  columns,
  showViewTypeSelector,
  searchPlaceholder = 'Search',
  noDataMessage = 'No data',
  viewType,
  setType,
  onSearch,
  filters,
  onClearSearch,
}) => {
  const [selectedViewType, setViewType] = useState(viewType || defaultViewType);
  const [searchString, setSearchString] = useState(null);
  const [searchIndex] = useState(searchKeys ? Fuse.createIndex(searchKeys, data) : null);
  const results = searchKeys ? searchData({ data, searchIndex, searchKeys, searchString }) : data;

  useEffect(() => {
    const url = new URL(window.location);
    url.searchParams.set('viewType', selectedViewType); // Update the URL with the selected view type
    window.history.replaceState({}, '', url); // Replace the current URL without reloading
  }, [selectedViewType]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const viewTypeFromUrl = urlParams.get('viewType');
    if (viewTypeFromUrl) {
      setViewType(viewTypeFromUrl); // Set the view type from URL if it exists
    }
  }, []);

  return (
    <Card
      border={selectedViewType === 'table' || selectedViewType === 'list'}
      floating
      bodyBackground="none"
      minHeight="600px"
      loading={data?.length > 0 ? false : loading}
      actionComponent={
        (searchKeys || showViewTypeSelector || filters) && (
          <ActionWrapper className="gap-2 flex-col md:flex-col">
            {searchKeys && (
              <Search
                loading={loading}
                allowClear
                style={{ width: 300 }}
                placeholder={searchPlaceholder}
                onSearch={value => {
                  if (value === '' || value === null) {
                    onClearSearch && onClearSearch();
                  }
                  const searchQuery = value ? value : undefined;
                  if (onSearch) {
                    onSearch(searchQuery);
                  } else {
                    setSearchString(searchQuery);
                  }
                  // if (value) {
                  //   setSearchString(value);
                  // } else {
                  //   setSearchString(null);
                  // }
                }}
              />
            )}

            {showViewTypeSelector && (
              <RadioGroup
                value={selectedViewType}
                onChange={e => {
                  setType && setType(e.target.value);
                  setViewType(e.target.value);
                }}
              >
                {onGridRender && (
                  <Tooltip title="Grid View">
                    <Radio.Button
                      value="grid"
                      icon="appstore"
                      onClick={() => {
                        setType && setType('grid');
                        setViewType('grid');
                      }}
                    >
                      <Icon type="appstore" />
                    </Radio.Button>
                  </Tooltip>
                )}
                {onRowRender && (
                  <Tooltip title="List View">
                    <Radio.Button
                      value="list"
                      icon="ordered-list"
                      onClick={() => {
                        setType && setType('list');
                        setViewType('list');
                      }}
                    >
                      <Icon type="ordered-list" />
                    </Radio.Button>
                  </Tooltip>
                )}
                {columns && (
                  <Tooltip title="Table View">
                    <Radio.Button
                      value="table"
                      icon="table"
                      onClick={() => {
                        setType && setType('table');
                        setViewType('table');
                      }}
                    >
                      <Icon type="table" />
                    </Radio.Button>
                  </Tooltip>
                )}
              </RadioGroup>
            )}
            {filters && filters}
          </ActionWrapper>
        )
      }
    >
      <div className="">
        {selectedViewType === 'grid' && (
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-2">
            {results.length === 0 && <div className="">{noDataMessage}</div>}
            {results.map((item, index) => (
              <div key={index} className="">
                {onGridRender(item)}
              </div>
            ))}
          </div>
        )}
        {selectedViewType === 'list' && (
          <RowFlexContainer>
            {results.map((item, index) => (
              <RowItem key={index} minHeight={itemMinHeight}>
                <div onClick={onClick}>{onRowRender(item)}</div>
              </RowItem>
            ))}
          </RowFlexContainer>
        )}
        {selectedViewType === 'table' && (
          <Table pagination={false} columns={columns} dataSource={results} />
        )}
      </div>
    </Card>
  );
};

export default DataDisplay;
