import { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useGlobal } from 'reactn';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import { Avatar, Tooltip, Tag, Input, Select } from 'antd';
import randomcolor from 'randomcolor';
import Trianglify from 'react-trianglify';
import classNames from 'classnames';
import numeral from 'numeral';
import Card from '../../common/components/Card';
import DataDisplay from '../../common/components/DataDisplay';
import Button from '../../common/components/Button';
import Modal from '../../common/components/Modal';
import NewProjectForm from '../../admin/components/NewProjectForm';
import ProjectFinancialSummary from './ProjectFinancialSummary';
import { Checkbox } from '@mantine/core';
import { CSVLink } from 'react-csv';
import useTaxonomy from '../../../hooks/useTaxonomy'; // Import the custom hook

const { Search } = Input;
const { Option } = Select;

const GET_CLIENTS = gql`
  query {
    projectManagement {
      clients {
        _id
        name
      }
    }
  }
`;

const ProjectList = ({
  projects = [],
  loading,
  history,
  title,
  onMore,
  onSearch,
  onClearSearch,
  onFilter,
  onSort,
  showFilters,
  showSearch,
  filter,
  sort,
  searchQuery,
  limit,
}) => {
  const { loading: loadingClients, data } = useQuery(GET_CLIENTS);
  const { loading: loadingTaxonomy, data: taxonomyQuery } = useTaxonomy('Status'); // Use the custom hook
  const statuses = taxonomyQuery?.taxonomy?.terms || [];
  const { loading: loadingSectors, data: sectorsQuery } = useTaxonomy('Sectors'); // Use the custom hook
  const sectors = sectorsQuery?.taxonomy?.terms || [];
  const [modalVisible, setModalVisible] = useState(false);
  const [user] = useGlobal('user');
  const isAdmin = user.groups.includes('admin');
  const isProjectManagement = user.groups.includes('project-management');
  const [sortType, setSortType] = useState(undefined);
  const [showSortOrder, setShowSortOrder] = useState(sort?.sortBy ? true : false);
  const [lockEditing] = useGlobal('lockEditing');
  const [refetching, setRefetching] = useState(false);

  const { location } = history;
  const { pathname } = location;

  const getClientName = ({ id, name }) => {
    if (name) return data?.projectManagement?.clients.find(client => client.name === name);
    return data?.projectManagement?.clients.find(client => client._id === id);
  };

  return (
    <Card
      floating
      bodyBackground="none"
      padded={false}
      title={title}
      actionComponent={
        (isAdmin || isProjectManagement) && (
          <div>
            <Button disabled={lockEditing} onClick={() => setModalVisible(true)}>
              Add Project
            </Button>
            <Modal
              title="Create New Project"
              visible={modalVisible}
              footer={null}
              onCancel={() => setModalVisible(false)}
            >
              <NewProjectForm
                onComplete={projectId => {
                  setModalVisible(false);
                  history.push(`/project-management/projects/${projectId}`);
                }}
                notifyUser={false}
              />
            </Modal>
          </div>
        )
      }
    >
      <DataDisplay
        filters={
          <>
            {showSearch && (
              <div className="flex justify-between">
                <Search
                  allowClear
                  loading={searchQuery && loading}
                  style={{ width: 300 }}
                  placeholder="Search Projects"
                  onSearch={onSearch}
                  defaultValue={searchQuery}
                />
                <Button>
                  <CSVLink
                    headers={[
                      { label: 'Project', key: 'name' },
                      { label: 'Client', key: 'client.name' },
                      { label: 'Project Code', key: 'projectCode' },
                      { label: 'Status', key: 'status' },
                      { label: 'Industry', key: 'sectors' },
                      { label: 'Budget Used', key: 'budgetUsed' },
                      { label: 'Archived', key: 'archived' },
                    ]}
                    filename={'Projects_' + Date.now() + '.csv'}
                    data={projects?.map(project => ({
                      ...project,
                      budgetUsed: numeral(project.budgetUsed).format('0,0.0%'),
                      archived: project.archived ? 'Yes' : 'No',
                      sectors: project.sectors?.map(sector => sector).join(', '),
                    }))}
                    className="btn"
                  >
                    Export CSV
                  </CSVLink>
                </Button>
              </div>
            )}
            {showFilters && (
              <div className="flex flex-col md:flex-row w-full justify-between">
                <div className="flex gap-2 flex-col md:flex-row">
                  <Select
                    loading={filter.budgetUsed && loading}
                    value={filter.budgetUsed ? String(filter.budgetUsed * 100) : undefined}
                    allowClear
                    placeholder="Filter by Budget"
                    style={{ width: 175 }}
                    onChange={value => {
                      onFilter({ budgetUsed: Number(value) / 100 });
                    }}
                  >
                    <Option value="15">15% Budget Used</Option>
                    <Option value="25">25% Budget Used</Option>
                    <Option value="50">50% Budget Used</Option>
                    <Option value="75">75% Budget Used</Option>
                    <Option value="100">100% Budget Used</Option>
                  </Select>

                  <Select
                    allowClear
                    showSearch
                    disabled={loadingClients}
                    value={getClientName({ id: filter.client })?.name}
                    loading={filter.client && loading}
                    placeholder="Filter by Client"
                    style={{ width: 175 }}
                    onChange={name => {
                      onFilter({ client: getClientName({ name })?._id });
                    }}
                  >
                    {data?.projectManagement?.clients
                      ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map(client => (
                        <Option key={client._id} value={client.name}>
                          {client.name}
                        </Option>
                      ))}
                  </Select>
                  <Select
                    loading={(filter.sector && loading) || loadingSectors}
                    value={filter.sector ? filter.sector : undefined}
                    allowClear
                    placeholder="Filter by Industry"
                    style={{ width: 175 }}
                    onChange={value => {
                      onFilter({ sector: value });
                    }}
                  >
                    {sectors?.map(sector => (
                      <Option key={sector.name} value={sector.name}>
                        {sector.name}
                      </Option>
                    ))}
                  </Select>
                  <Select
                    loading={(filter.status && loading) || loadingTaxonomy}
                    value={filter.status ? filter.status : undefined}
                    allowClear
                    placeholder="Filter by Status"
                    style={{ width: 175 }}
                    onChange={value => {
                      onFilter({ status: value });
                    }}
                  >
                    {statuses?.map(status => (
                      <Option key={status.name} value={status.name}>
                        {status.name}
                      </Option>
                    ))}
                  </Select>
                  <div className="flex items-center">
                    <Checkbox
                      checked={filter?.archived}
                      onChange={value => {
                        onFilter({ archived: value.target.checked });
                      }}
                      label="Include Archived Projects"
                    />
                  </div>
                </div>
                <div className="flex gap-2">
                  <Select
                    value={sort.sortBy}
                    allowClear
                    placeholder="Sort by"
                    style={{ width: 175 }}
                    onChange={value => {
                      onSort({ sortBy: value });
                      if (value === 'budgetUsed') {
                        setSortType('numeric');
                      }
                      if (value === 'name') {
                        setSortType('lexical');
                      }
                      if (value) {
                        setShowSortOrder(true);
                      } else {
                        setShowSortOrder(false);
                      }
                    }}
                  >
                    <Option value="budgetUsed">Budget Used</Option>
                  </Select>
                  {showSortOrder && (
                    <Select
                      value={sort.sortOrder ? String(sort.sortOrder) : '1'}
                      placeholder="Sort order"
                      style={{ width: 175 }}
                      onChange={value => {
                        onSort({ sortOrder: Number(value) });
                      }}
                    >
                      <Option value="-1">{sortType === 'numeric' ? 'Highest First' : 'Z-A'}</Option>
                      <Option value="1">{sortType === 'numeric' ? 'Lowest First' : 'A-Z'}</Option>
                    </Select>
                  )}
                </div>
              </div>
            )}
          </>
        }
        data={
          projects.map((project, index) => ({
            ...project,
            index: index + 1,
            rowKey: project._id,
          })) || []
        }
        showViewTypeSelector
        loading={loading && !refetching}
        itemMinWidth="300px"
        itemMinHeight="200px"
        defaultViewType={localStorage.getItem('projectListViewType') || 'grid'}
        noDataMessage="No Projects Found"
        setType={value => localStorage.setItem('projectListViewType', value)}
        columns={[
          {
            title: 'Index',
            dataIndex: 'index',
          },
          {
            title: 'Name',
            dataIndex: 'name',
            render: (text, project) => (
              <Link key={project._id} to={`${pathname}/${project._id}`}>
                {text}
              </Link>
            ),
          },
          { title: 'Client', dataIndex: 'client.name' },
          { title: 'Project Code', dataIndex: 'projectCode' },
          {
            title: 'Industry',
            render: project => (
              <div>{project.sectors?.map(sector => sector).join(', ')}</div>
            ),
          },
          {
            title: 'Status',
            render: project => (
              <>
                {project.status && <Tag>{project.status}</Tag>}
                {project.archived && <Tag>Archived</Tag>}
              </>
            ),
          },
          {
            title: 'Budget Used',
            dataIndex: 'budgetUsed',
            render: budgetUsed => numeral(budgetUsed).format('%0.0'),
          },
        ]}
        onGridRender={project => {
          const { resources } = project;
          const resourcesToShow = 5;
          return (
            <Link key={project._id} to={`${pathname}/${project._id}`}>
              <div
                className={classNames(
                  'relative bg-white border border-solid border-gray-200 rounded-md text-gray-600 h-full overflow-hidden flex flex-col cursor-pointer',
                  {
                    'opacity-50 hover:opacity-100': project?.archived,
                  },
                )}
              >
                <div className="opacity-50">
                  <Trianglify
                    cell_size={300}
                    output="svg"
                    seed={project._id}
                    width={1150}
                    height={20}
                    x_colors={[
                      randomcolor({ seed: project._id, luminosity: 'light' }),
                      randomcolor({ seed: project._id, luminosity: 'dark' }),
                    ]}
                  />
                </div>
                <div className="absolute top-0 right-0 my-2">
                  {project?.archived && <Tag>Archived</Tag>}
                  {project?.status && <Tag>{project.status}</Tag>}
                </div>
                <div className="p-4 relative">
                  <h2 className="mb-0 truncate">{project?.name || 'No Name'}</h2>
                  <div className="truncate">{project.client?.name}</div>
                  <div className="flex justify-between">
                    <div className="text-gray-500">{project.projectCode}</div>
                    <div className="flex mb-2 items-center justify-end">
                      {resources
                        ?.filter(r => r.user || r.personnel)
                        ?.filter((_, i) => i < resourcesToShow)
                        ?.map(r => {
                          let name;
                          // start with name on user object
                          if (r?.user?.firstName && r?.user?.lastName) {
                            name = `${r.user?.firstName} ${r.user?.lastName}`;
                          }
                          // if personnel object exist, use name on personnel object
                          if (r?.personnel?.name) {
                            name = r.personnel.name;
                          }
                          return (
                            <Tooltip key={r._id} title={name}>
                              <Avatar
                                style={{
                                  backgroundColor: randomcolor({
                                    seed: r.user?.emails[0].address,
                                    luminosity: 'dark',
                                  }),
                                  verticalAlign: 'middle',
                                  marginLeft: '-15px',
                                  border: '1px solid white',
                                }}
                                src={r.user?.imageUrl}
                              >
                                {`${r.user?.firstName[0]}${r.user?.lastName[0]}`}
                              </Avatar>
                            </Tooltip>
                          );
                        })}
                      {resources?.length > resourcesToShow && (
                        <Tooltip
                          title={`${
                            resources?.length - resourcesToShow
                          } more team members on this project`}
                        >
                          <div className="ml-1 text-gray-600">{`+${
                            resources?.length - resourcesToShow
                          }`}</div>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                  <div className="relative overflow-hidden" style={{ height: '60px' }}>
                    <p>
                      {project.description?.length > 300
                        ? project.description.substring(0, 300 - 3) + '...'
                        : project.description}
                    </p>
                    <div
                      className="absolute bottom-0 left-0 right-0 bg-white h-10"
                      style={{
                        background:
                          'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,.2), rgba(255,255,255,1))',
                      }}
                    ></div>
                  </div>
                  {/* <div className="flex justify-start items-end mt-4 w-full">
                      <div className="h-10 pointer-events-none w-full">
                        <ResponsiveContainer width="100%" height="100%">
                           <AreaChart
                            width={200}
                            height={100}
                            data={project.activity}
                            margin={{
                              top: 0,
                              right: 0,
                              left: 0,
                              bottom: 0,
                            }}
                          >
                            <Area
                              type="monotone"
                              dataKey="hours"
                              stroke={randomcolor({ seed: project._id, luminosity: 'dark' })}
                              fill={randomcolor({ seed: project._id, luminosity: 'light' })}
                            />
                          </AreaChart>
                          <LineChart height={50} data={project.activity}>
                            <Line type="monotone" dataKey="hours" dot={null} stroke="gray" />
                          </LineChart>
                        </ResponsiveContainer>
                      </div>
                    </div> */}
                </div>
                <div className="mt-auto h-24 flex justify-center  items-center border-0 border-t border-solid border-gray-200">
                  <ProjectFinancialSummary
                    className="w-full"
                    project={project}
                    budgetUsed={project.budgetUsed}
                  />
                </div>
              </div>
            </Link>
          );
        }}
      />
      {onMore && (!loading || refetching) && projects.length > 0 && projects.length >= limit && (
        <div className="w-full flex justify-center">
          <Button
            loading={refetching}
            onClick={async () => {
              setRefetching(true);
              await onMore({ offset: projects.length });
              setRefetching(false);
            }}
          >
            Load More
          </Button>
        </div>
      )}
    </Card>
  );
};

export default ProjectList;