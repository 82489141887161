/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

export const ADD_DELIVERABLE = gql`
  mutation addDeliverable(
    $projectId: ID!
    $milestoneName: String!
    $deliverableName: String!
    $budget: Float
    $budgetPercent: Float
  ) {
    addDeliverable(
      projectId: $projectId
      input: {
        milestoneName: $milestoneName
        deliverableName: $deliverableName
        budget: $budget
        budgetPercent: $budgetPercent
      }
    )
  }
`;

export const UPDATE_DELIVERABLE = gql`
  mutation UpdateDeliverable(
    $id: ID!
    $deliverableName: String
    $milestoneName: String
    $accountingCode: String
    $accountingCodeSuffix: String
    $budget: Float
    $rate: Float
    $blendedRate: Float
    $budgetPercent: Float
    $expenseId: String
    $expenseBudget: Float
    $disabled: Boolean
    $startDate: Date
    $endDate: Date
    $billingDate: Date
    $status: String
    $trackAgainstTimeOff: String
  ) {
    updateDeliverable(
      id: $id
      input: {
        deliverableName: $deliverableName
        milestoneName: $milestoneName
        accountingCode: $accountingCode
        accountingCodeSuffix: $accountingCodeSuffix
        budget: $budget
        rate: $rate
        blendedRate: $blendedRate
        budgetPercent: $budgetPercent
        expenseId: $expenseId
        expenseBudget: $expenseBudget
        disabled: $disabled
        startDate: $startDate
        endDate: $endDate
        billingDate: $billingDate
        status: $status
        trackAgainstTimeOff: $trackAgainstTimeOff
      }
    )
  }
`;
