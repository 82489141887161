import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { withApollo } from 'react-apollo';
import Input from '../../common/components/Input';
import { GET_USER_TIMESHEET } from '../queries';
import { UPDATE_HOURS_MUTATION, ADD_HOURS_MUTATION } from '../mutations';
import { showError, showSuccess } from '../../../modules/common/notifications';

const StyledInput = styled(Input)`
  background: ${props => (props.background ? props.background : 'white')};
  text-align: center;
  border-radius: 0;
  &[type='number'] {
    appearance: textfield;
  }
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  border-color: ${props => (props.hasError ? 'red' : 'lightgray')} !important;
`;

const LockedHours = styled.span`
  font-size: 1.1em;
`;

const errorMessage =
  'There was an issue saving your hours. Please refresh page and try again. If issue persists, contact support.';

function TimesheetGridRowHour(props) {
  const [hours, setHours] = useState(props.task ? props.task.hours : null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHours(props.task ? props.task.hours : null);
  }, [props.task]);

  const handleSave = e => {
    const value = e.target.value === '' ? null : parseFloat(e.target.value);
    save(value);
  };

  const setUpdating = (isUpdating, delay, hasError = false) => {
    if (delay) {
      setTimeout(() => {
        setIsUpdating(isUpdating);
        setHasError(hasError);
      }, 300);
    } else {
      setIsUpdating(isUpdating);
      setHasError(hasError);
    }
  };

  const save = value => {
    const { client, task, endDate, user } = props;
    const currentHours = task ? task.hours : null;
    if (currentHours !== value) {
      setUpdating(true);
      if (task) {
        client
          .mutate({
            mutation: UPDATE_HOURS_MUTATION,
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: GET_USER_TIMESHEET,
                variables: {
                  endDate,
                  user: user._id,
                },
              },
            ],
            variables: {
              taskId: task._id,
              hours: value,
            },
          })
          .then(() => {
            showSuccess({ message: `Successfully updated hours` });
            setUpdating(false, 300);
          })
          .catch(() => {
            showError({
              duration: 6,
              message: errorMessage,
            });
            setUpdating(false, 300, true);
          });
      } else {
        const { newTaskRequiredFields } = props;
        client
          .mutate({
            mutation: ADD_HOURS_MUTATION,
            awaitRefetchQueries: true,
            variables: {
              ...newTaskRequiredFields,
              hours: value,
              user: user._id,
            },
            refetchQueries: [
              {
                query: GET_USER_TIMESHEET,
                variables: {
                  endDate,
                  user: user._id,
                },
              },
            ],
          })
          .then(() => {
            showSuccess({ message: `Successfully added hours` });
            setUpdating(false, 300);
          })
          .catch(() => {
            showError({
              duration: 6,
              message: errorMessage,
            });
            setUpdating(false, 300, true);
          });
      }
    }
  };

  const handleChange = e => {
    const { value } = e.target;
    const newHours = value === '' ? null : Number(value);
    if (value >= 0) {
      setHours(newHours);
    }
  };

  const { isLocked, backgroundColor, isRemoving, disabled } = props;

  if (isLocked) {
    return <LockedHours>{hours || undefined}</LockedHours>;
  }

  if (disabled) {
    return <StyledInput disabled value={hours || undefined} />;
  }

  return (
    <StyledInput
      hasError={hasError}
      disabled={isUpdating || isLocked || isRemoving}
      onChange={handleChange}
      onBlur={handleSave}
      onPressEnter={handleSave}
      background={backgroundColor || 'white'}
      value={hours}
      type="number"
      step="0.25"
    />
  );
}

export default withApollo(TimesheetGridRowHour);
